import React, {useState, useEffect} from 'react';
import { Table, Container } from 'reactstrap';
import Layout from '../../../components/layout'
import axios from "axios";
import Moment from "react-moment"


const UserList = (props) => {


    const [appState, setAppState] = useState({
        loading: true,
        notes: null,
    });

    useEffect(() => {
        setAppState({ loading: true });
        let location = window.location
        const apiUrl = location.origin +'/api/getAllContacts'; //'http://localhost:8888/api/getAllContacts';

        axios.get(apiUrl, { headers : 
            { crossdomain: true }
            }).then(
        (repos) => {
          const notes = repos.data.notes.reverse();
          setAppState({ loading: false, notes: notes });
        });
      }, [setAppState]);

    return (
        <Layout>
            <Container>
                <div className="table_data">
                <Table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Company</th>
                            <th>Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                    {appState.loading !== true ? appState.notes.map((note) => {
                        var t = new Date( parseInt(note._ts/1000));
                        //var formatted = t.format("dd.mm.yyyy hh:MM:ss");
                        return (
                        <tr key={note._id}>
                            <td><Moment format="YYYY/MM/DD">{t}</Moment></td>
                            <td>{note.firstName}</td>
                            <td>{note.lastName}</td>
                            <td>{note.email}</td>
                            <td>{note.phone}</td>
                            <td>{note.company}</td>
                            <td>{note.text}</td>
                        </tr>
                        );
                    }): "loading.."}

                    </tbody>
                </Table>
                </div>
            </Container>
        </Layout>
    );
}

export default UserList;